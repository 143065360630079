// © Microsoft Corporation. All rights reserved.
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
//import './indexMasdr.css';
import './indexSABusiness.css';
import setLanguage from 'translation'; 

import App from './App';
import { Provider } from '@fluentui/react-northstar';
import { svgIconStyles } from '@fluentui/react-northstar/dist/es/themes/teams/components/SvgIcon/svgIconStyles';
import { svgIconVariables } from '@fluentui/react-northstar/dist/es/themes/teams/components/SvgIcon/svgIconVariables';
import * as siteVariables from '@fluentui/react-northstar/dist/es/themes/teams/siteVariables';
import {Route , BrowserRouter as Router  ,Routes} from 'react-router-dom'
import WaitingScreen  from 'components/WaitingScreen';
import WelcomeScreen  from 'components/WelcomeScreen';
import Rating from 'components/Rating';
import Chat from 'components/Chat';
import AfterWorkingHours from 'components/AfterWorkingHours';
import AfterRating from 'components/AfterRating';
import WaitinfSessionExpired from 'components/WaitingSessionExpired';
import OTPWrapper from 'components/OTPInput';
import AbnormalRating from 'components/AbnormalRating';


const iconTheme = {
  componentStyles: {
    SvgIcon: svgIconStyles
  },
  componentVariables: {
    SvgIcon: svgIconVariables
  },
  siteVariables
};

 


  const openUrl = (url: string) =>{
    //window.location.href = url;
    window.open(url , "_blank");
  }
  const handleLanguageChange = (lang: string)=>{
    setLanguage(lang);
    localStorage.setItem("lang", lang);
    
  }
   
  ReactDOM.render(
    
    <div className="full-h">
      <div className="headerline"></div>
      
    <div id="headerx" className='row' style={{direction:"rtl"}}>
       <div  className='col-md-2 logoBoxSABusiness' id="logoBox"       ></div>
       <div className='col-md-3'  ></div>
       <div className='col-md-2' id="internalLogo"        ></div>
       <div className='col-md-2' id="internalLogoEN"        ></div>
    </div>
    
     <div className="main-container" >     
     <div id="languageBar" className='row' style={{display: "none"}}>
      <select onChange={(e) => handleLanguageChange(e.target.value)} id="languageSelector">
        <option value="ar">عربى</option>
        <option value="en">English</option>
      </select>
    </div>
         <div className='row' id="content">
         <div className='col-md-12'>
           <Router>
           <Routes>          
                <Route  path='/' element={<Provider theme={iconTheme} className=""><App /> </Provider>} />
                <Route  path='/waitingScreen' element={<WaitingScreen/>}/>                      
                <Route  path='/welcomeScreen' element={<WelcomeScreen/>}/>
                <Route  path='/rating' element={<Rating/>}/>
                <Route  path='/chat' element={<Chat/>}/>
                <Route  path='/afterWorkingHours' element={<AfterWorkingHours/>}/>
                <Route  path='/afterRating' element={<AfterRating/>}/>
                <Route  path='/waitingTimeExpired' element={<WaitinfSessionExpired/>}/>
                <Route  path='/otp' element={<OTPWrapper/>}/>
                <Route  path='/abnormalRating' element={<AbnormalRating/>}/>
                
           </Routes>
         </Router>
         </div>
         </div>
     </div>

     <div className="footer" onClick={() => openUrl('https://masdr.sa/home')}> <span>جميع الحقوق محفوظة © 2023</span><span className="source"  >Powered by </span><span className="sourceimg"></span><span className="endaklogo_x"></span></div>
     
  </div>,
  document.getElementById('root'),() =>{

   /*  const languageSelector = document.getElementById("languageSelector") as HTMLInputElement;
    const language = localStorage.getItem("lang") || "en"; 
    languageSelector.value = language; */
  //  const urlParams = new URLSearchParams(window.location.search);
//const client = urlParams.get('client');
    
    //const logoBox = document.getElementById("logoBox") as HTMLDivElement;
    //logoBox.className = "logoBoxSABusiness";
    //logoBox.className = "logoBoxSABusiness";
    //const internalLogo = document.getElementById("internalLogo") as HTMLDivElement;
   /*  if(client == "masdr"){
      logoBox.className = "logoBoxMasdr";
    }else{
      logoBox.className = "logoBox";
    } */
   // internalLogo.className = "internalLogo";    
  }
);
 