import React, { Component } from "react"
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';
import appconfig from "./../appconfig.json";
import { Provider } from 'react-redux';
import { store, persistedStore } from "App";
import { PersistGate } from "redux-persist/integration/react";
import {translations} from 'translation'; 



class Rating extends Component {

  constructor(public props: any) {
    super(props);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
 
  }

  common = store.getState().common;
  queryParams = new URLSearchParams(window.location.search);
  isPublic = this.queryParams.get("isPublic");
  source = this.queryParams.get("source");

  showUnhappyReason = () => {
    const reasonLabel = document.getElementById('unhappyReasonLabel');
    if (reasonLabel) {
      reasonLabel.style.display = '';
    }

    const reasonText = document.getElementById('unhappyReason');
    if (reasonText) {
      reasonText.style.display = '';
    }
  }

  hideUnhappyReason = () => {
    const reasonLabel = document.getElementById('unhappyReasonLabel');
    if (reasonLabel) {
      reasonLabel.style.display = 'none';
    }

    const reasonText = document.getElementById('unhappyReason');
    if (reasonText) {
      reasonText.style.display = 'none';
    }
  }

  setRating = (rateVal: string) => {

    //unselect all other ratings
    for (var i = 1; i <= 5; i++) {
      const divElementId = "divRating_" + i;
      var element = document.getElementById(divElementId);
      if (element) {
        element.style.border = '1px solid #EFEFEF';
      }
    }

    if (rateVal == '1' || rateVal == '2') {
      this.showUnhappyReason();
    } else {
      this.hideUnhappyReason();
    }

    this.setState({
      rating: rateVal
    })

    const divElementId = "divRating_" + rateVal;
    var element = document.getElementById(divElementId);
    if (element) {
      element.style.border = '1px solid #666';
    }



  }


  setEmpRating = (rateVal: string) => {

    //unselect all other ratings
    for (var i = 1; i <= 5; i++) {
      const divElementId = "divEmpRating_" + i;
      var element = document.getElementById(divElementId);
      if (element) {
        element.style.border = '1px solid #EFEFEF';
      }
    }

/* 
    if (rateVal == '1' || rateVal == '2') {
      this.showUnhappyReason();
    } else {
      this.hideUnhappyReason();
    } */

    this.setState({
      empRating: rateVal
    })

    const divElementId = "divEmpRating_" + rateVal;
    var element = document.getElementById(divElementId);
    if (element) {
      element.style.border = '1px solid #666';
    }

  }


  setDigitalHumanRating = (rateVal: string) => {

    for (var i = 1; i <= 5; i++) {
      const divElementId = "divDHRating_" + i;
      var element = document.getElementById(divElementId);
      if (element) {
        element.style.border = '1px solid #EFEFEF';
      }
    }
 

    this.setState({
      digitalHumanRating: rateVal
    })

    const divElementId = "divDHRating_" + rateVal;
    var element = document.getElementById(divElementId);
    if (element) {
      element.style.border = '1px solid #666';
    }

  }


  state = {
    rating: "0",
    empRating: "0",
    digitalHumanRating:"0",
    comments: "",
    reasonForBadRating: ""
  }

  handleCommentChange(event: any) {
    this.setState({
      comments: event.target.value
    });
  }

  handleReasonChange(event: any) {
    this.setState({
      reasonForBadRating: event.target.value
    })
  }


  saveUserFeedback = () => {
    const languageSelector = document.getElementById("languageSelector") as HTMLInputElement;
    let language = languageSelector.value;
    if (this.state.rating == "0") {
      //alert("رجاء تقييم الخدمة");
      alert(translations[language]["service_rating_is_required"]);
      return;
    }

    if (this.state.empRating == "0") {
      //alert("رجاء تقييم الموظف");
      alert(translations[language]["employee_rating_is_required"]);
      return;
    }

    
    //alert("digitalHumanRating = "+this.state.digitalHumanRating);

    if (this.source == "DigitalHuman" &&  this.state.digitalHumanRating == "0") {
      //alert("رجاء تقييم الانسان الرقمى");
      alert(translations[language]["digital_human_rating_is_required"]);
      return;
    }
    if (this.state.rating == "1" || this.state.rating == "2") {
      if (this.state.reasonForBadRating == "") {
        //alert("الرجاء ذكر عدم رضاك عن الخدمة");
        alert(translations[language]["unsatisfaction_reason_is_required"]);
        return;
      }
    }
    const body = {
      "ratings": this.state.empRating,
      "serviceRatings": this.state.rating,
      "DigitalHumanRating":this.state.digitalHumanRating,
      "comments": this.state.comments,
      "reasonForBadRating": this.state.reasonForBadRating
    };

    /*  const options = {
       headers: {
           'api_token': this.common.token,
           'Access-Control-Allow-Origin': "*"
       }
     }; */
    const config = {
      headers: {
        // Authorization: `Bearer ${this.common.token}`,
        api_token: this.common.token
      }
    };

    //alert("saveUserFeedback token >>"+this.common.token);
    console.log("save user feedback body > " + body);
    console.log("save user feedback config > " + config);


    const meetingID = this.queryParams.get("meetingID");

    const url = appconfig.azure_reports_url + '/api/customer-meeting/feedback/' + meetingID;
    console.log("save user feedback url > " + url);
    axios.post(url, body, config).then(response => {
      console.log("user feedback saved with status = " + response.status);
      let afterRatingUrl = window.location.href.replace("rating", "afterRating")
      window.location.href = afterRatingUrl + "&isPublic=" + this.isPublic;
    });

  }


  render() {
    let className = "";
    if (this.isPublic == "true") {
      className = "main-container";
    } else {
      className = "private-container";
    }
 
    return (
      <div id="main">
        <div className={className}>

          <div className="custom-top-card" data-i18n="rating_message">نأمل منك المشاركة فى هذا الاستبيان الذى يهدف إلى قياس رضاك والاستفادة من ملاحظاتك التى ستساهم بشكل فعّال فى فريق العمل لتحسين تجربتك</div>

          <br></br>
          <div className="row">
            <div className="col-lg-12" >
              <h5 className="t2" data-i18n="rating_q1"> ما مدى رضاك عن جودة الخدمة المقدمة ؟</h5>
            </div>
          </div>

          <br></br>

          <div className="row">

            <div className="col-lg-1"></div>
            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setRating('5')}>
              <div className="rating" id="divRating_5">
                <div className="rating-svg">
                  <svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_1" data-name="Ellipse 1" className="cls-3" cx="15" cy="15" r="15" />
                    <path id="Path_876" data-name="Path 876" className="cls-4" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-88.754 -117.795)" />
                    <path id="Path_877" data-name="Path 877" className="cls-4" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_874" data-name="Path 874" className="cls-4" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Subtraction_1" data-name="Subtraction 1" className="cls-4" d="M7.085,6.044A7.168,7.168,0,0,1,2.407,4.305,7.183,7.183,0,0,1,0,0H1.325A5.88,5.88,0,0,0,7.085,4.74,5.879,5.879,0,0,0,12.845,0H14.17a7.183,7.183,0,0,1-2.407,4.3A7.168,7.168,0,0,1,7.085,6.044Z" transform="translate(7.915 16.957)" />
                  </svg>
                </div>
                <span data-i18n="fully_satisfied">راضى تماما</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setRating('4')}>
              <div className="rating" id="divRating_4">
                <div className="rating-svg">
                  <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="30.593" height="30" viewBox="0 0 30.593 30">
                    <circle id="Ellipse_2" data-name="Ellipse 2" className="cls-5" cx="15" cy="15" r="15" />
                    <path id="Path_868" data-name="Path 868" className="cls-6" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" transform="translate(0.593)" />
                    <path id="Path_869" data-name="Path 869" className="cls-6" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_870" data-name="Path 870" className="cls-6" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_871" data-name="Path 871" className="cls-6" d="M118.711,224.19a.748.748,0,0,0-1.056.058,8.321,8.321,0,0,1-12.349,0,.748.748,0,0,0-1.114,1,9.817,9.817,0,0,0,14.578,0A.748.748,0,0,0,118.711,224.19Z" transform="translate(-95.887 -206.141)" />
                  </svg>
                </div>
                <span data-i18n="satisfied">راضى</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setRating('3')}>
              <div className="rating" id="divRating_3">
                <div className="rating-svg">
                  <svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_3" data-name="Ellipse 3" className="cls-7" cx="15" cy="15" r="15" />
                    <path id="Path_887" data-name="Path 887" className="cls-8" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_889" data-name="Path 889" className="cls-8" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_886" data-name="Path 886" className="cls-8" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Path_890" data-name="Path 890" className="cls-8" d="M109.364,224H96.636a.675.675,0,0,0,0,1.348h12.727a.675.675,0,0,0,0-1.348Z" transform="translate(-88 -204.422)" />
                  </svg>
                </div>
                <span data-i18n="rating_equal">محايد</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setRating('2')}>
              <div className="rating" id="divRating_2">
                <div className="rating-svg">
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_4" data-name="Ellipse 4" className="cls-9" cx="15" cy="15" r="15" />
                    <path id="Path_879" data-name="Path 879" className="cls-0" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Path_880" data-name="Path 880" className="cls-0" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_881" data-name="Path 881" className="cls-0" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_884" data-name="Path 884" className="cls-0" d="M118.711,228.3a.748.748,0,0,1-1.056-.058,8.321,8.321,0,0,0-12.349,0,.748.748,0,0,1-1.114-1,9.817,9.817,0,0,1,14.578,0A.748.748,0,0,1,118.711,228.3Z" transform="translate(-95.887 -206.141)" />
                  </svg>
                </div>
                <span data-i18n="rating_unsatisfies">غير راضى</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setRating('1')}>
              <div className="rating" id="divRating_1">
                <div className="rating-svg">
                  <svg id="Component_1_1" data-name="Component 1 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_5" data-name="Ellipse 5" className="cls-1" cx="15" cy="15" r="15" />
                    <path id="Path_892" data-name="Path 892" className="cls-2" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-88.754 -117.795)" />
                    <path id="Path_894" data-name="Path 894" className="cls-2" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_893" data-name="Path 893" className="cls-2" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Subtraction_2" data-name="Subtraction 2" className="cls-2" d="M1.325,6.044H0a7.176,7.176,0,0,1,14.17,0H12.845a5.87,5.87,0,0,0-11.52,0Z" transform="translate(7.915 16.958)" />
                  </svg>
                </div>
                <span data-i18n="fully_unsatisfied">غير راضى تماما</span>
              </div>
            </div>
          </div>


          <br></br>
          <div className="row">
            <div className="col-lg-12" >
              <h5 className="t2" data-i18n="rating_q2"> ما مدى رضاك عن جودة الخدمة المقدمة من الموظف ؟</h5>
            </div>
          </div>

          <br></br>

          <div className="row">

            <div className="col-lg-1"></div>
            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setEmpRating('5')}>
              <div className="rating" id="divEmpRating_5">
                <div className="rating-svg">
                  <svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_1" data-name="Ellipse 1" className="cls-3" cx="15" cy="15" r="15" />
                    <path id="Path_876" data-name="Path 876" className="cls-4" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-88.754 -117.795)" />
                    <path id="Path_877" data-name="Path 877" className="cls-4" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_874" data-name="Path 874" className="cls-4" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Subtraction_1" data-name="Subtraction 1" className="cls-4" d="M7.085,6.044A7.168,7.168,0,0,1,2.407,4.305,7.183,7.183,0,0,1,0,0H1.325A5.88,5.88,0,0,0,7.085,4.74,5.879,5.879,0,0,0,12.845,0H14.17a7.183,7.183,0,0,1-2.407,4.3A7.168,7.168,0,0,1,7.085,6.044Z" transform="translate(7.915 16.957)" />
                  </svg>
                </div>
                <span data-i18n="fully_satisfied">راضى تماما</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setEmpRating('4')}>
              <div className="rating" id="divEmpRating_4">
                <div className="rating-svg">
                  <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="30.593" height="30" viewBox="0 0 30.593 30">
                    <circle id="Ellipse_2" data-name="Ellipse 2" className="cls-5" cx="15" cy="15" r="15" />
                    <path id="Path_868" data-name="Path 868" className="cls-6" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" transform="translate(0.593)" />
                    <path id="Path_869" data-name="Path 869" className="cls-6" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_870" data-name="Path 870" className="cls-6" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_871" data-name="Path 871" className="cls-6" d="M118.711,224.19a.748.748,0,0,0-1.056.058,8.321,8.321,0,0,1-12.349,0,.748.748,0,0,0-1.114,1,9.817,9.817,0,0,0,14.578,0A.748.748,0,0,0,118.711,224.19Z" transform="translate(-95.887 -206.141)" />
                  </svg>
                </div>
                <span  data-i18n="satisfied">راضى</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setEmpRating('3')}>
              <div className="rating" id="divEmpRating_3">
                <div className="rating-svg">
                  <svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_3" data-name="Ellipse 3" className="cls-7" cx="15" cy="15" r="15" />
                    <path id="Path_887" data-name="Path 887" className="cls-8" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_889" data-name="Path 889" className="cls-8" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_886" data-name="Path 886" className="cls-8" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Path_890" data-name="Path 890" className="cls-8" d="M109.364,224H96.636a.675.675,0,0,0,0,1.348h12.727a.675.675,0,0,0,0-1.348Z" transform="translate(-88 -204.422)" />
                  </svg>
                </div>
                <span  data-i18n="rating_equal">محايد</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setEmpRating('2')}>
              <div className="rating" id="divEmpRating_2">
                <div className="rating-svg">
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_4" data-name="Ellipse 4" className="cls-9" cx="15" cy="15" r="15" />
                    <path id="Path_879" data-name="Path 879" className="cls-0" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Path_880" data-name="Path 880" className="cls-0" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_881" data-name="Path 881" className="cls-0" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_884" data-name="Path 884" className="cls-0" d="M118.711,228.3a.748.748,0,0,1-1.056-.058,8.321,8.321,0,0,0-12.349,0,.748.748,0,0,1-1.114-1,9.817,9.817,0,0,1,14.578,0A.748.748,0,0,1,118.711,228.3Z" transform="translate(-95.887 -206.141)" />
                  </svg>
                </div>
                <span  data-i18n="rating_unsatisfies">غير راضى</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setEmpRating('1')}>
              <div className="rating" id="divEmpRating_1">
                <div className="rating-svg">
                  <svg id="Component_1_1" data-name="Component 1 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_5" data-name="Ellipse 5" className="cls-1" cx="15" cy="15" r="15" />
                    <path id="Path_892" data-name="Path 892" className="cls-2" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-88.754 -117.795)" />
                    <path id="Path_894" data-name="Path 894" className="cls-2" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_893" data-name="Path 893" className="cls-2" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Subtraction_2" data-name="Subtraction 2" className="cls-2" d="M1.325,6.044H0a7.176,7.176,0,0,1,14.17,0H12.845a5.87,5.87,0,0,0-11.52,0Z" transform="translate(7.915 16.958)" />
                  </svg>
                </div>
                <span  data-i18n="fully_unsatisfied">غير راضى تماما</span>
              </div>
            </div>
          </div>

        
          {this.source ==  "DigitalHuman" &&
          <div>
          <br></br>
          <div className="row" >
            <div className="col-lg-12" >
              <h5 className="t2" data-i18n="rating_q3"> ما مدى رضاك العام عن خدمة الانسان الرقمي ؟</h5>
            </div>
          </div>

          <br></br>

          <div className="row" >

            <div className="col-lg-1"></div>
            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setDigitalHumanRating('5')}>
              <div className="rating" id="divDHRating_5">
                <div className="rating-svg">
                  <svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_1" data-name="Ellipse 1" className="cls-3" cx="15" cy="15" r="15" />
                    <path id="Path_876" data-name="Path 876" className="cls-4" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-88.754 -117.795)" />
                    <path id="Path_877" data-name="Path 877" className="cls-4" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_874" data-name="Path 874" className="cls-4" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Subtraction_1" data-name="Subtraction 1" className="cls-4" d="M7.085,6.044A7.168,7.168,0,0,1,2.407,4.305,7.183,7.183,0,0,1,0,0H1.325A5.88,5.88,0,0,0,7.085,4.74,5.879,5.879,0,0,0,12.845,0H14.17a7.183,7.183,0,0,1-2.407,4.3A7.168,7.168,0,0,1,7.085,6.044Z" transform="translate(7.915 16.957)" />
                  </svg>
                </div>
                <span  data-i18n="fully_satisfied">راضى تماما</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setDigitalHumanRating('4')}>
              <div className="rating" id="divDHRating_4">
                <div className="rating-svg">
                  <svg id="Component_4_1" data-name="Component 4 – 1" xmlns="http://www.w3.org/2000/svg" width="30.593" height="30" viewBox="0 0 30.593 30">
                    <circle id="Ellipse_2" data-name="Ellipse 2" className="cls-5" cx="15" cy="15" r="15" />
                    <path id="Path_868" data-name="Path 868" className="cls-6" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" transform="translate(0.593)" />
                    <path id="Path_869" data-name="Path 869" className="cls-6" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_870" data-name="Path 870" className="cls-6" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_871" data-name="Path 871" className="cls-6" d="M118.711,224.19a.748.748,0,0,0-1.056.058,8.321,8.321,0,0,1-12.349,0,.748.748,0,0,0-1.114,1,9.817,9.817,0,0,0,14.578,0A.748.748,0,0,0,118.711,224.19Z" transform="translate(-95.887 -206.141)" />
                  </svg>
                </div>
                <span  data-i18n="satisfied">راضى</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setDigitalHumanRating('3')}>
              <div className="rating" id="divDHRating_3">
                <div className="rating-svg">
                  <svg id="Component_3_1" data-name="Component 3 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_3" data-name="Ellipse 3" className="cls-7" cx="15" cy="15" r="15" />
                    <path id="Path_887" data-name="Path 887" className="cls-8" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_889" data-name="Path 889" className="cls-8" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_886" data-name="Path 886" className="cls-8" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Path_890" data-name="Path 890" className="cls-8" d="M109.364,224H96.636a.675.675,0,0,0,0,1.348h12.727a.675.675,0,0,0,0-1.348Z" transform="translate(-88 -204.422)" />
                  </svg>
                </div>
                <span  data-i18n="rating_equal">محايد</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setDigitalHumanRating('2')}>
              <div className="rating" id="divDHRating_2">
                <div className="rating-svg">
                  <svg id="Component_2_1" data-name="Component 2 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_4" data-name="Ellipse 4" className="cls-9" cx="15" cy="15" r="15" />
                    <path id="Path_879" data-name="Path 879" className="cls-0" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Path_880" data-name="Path 880" className="cls-0" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-87.754 -117.795)" />
                    <path id="Path_881" data-name="Path 881" className="cls-0" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_884" data-name="Path 884" className="cls-0" d="M118.711,228.3a.748.748,0,0,1-1.056-.058,8.321,8.321,0,0,0-12.349,0,.748.748,0,0,1-1.114-1,9.817,9.817,0,0,1,14.578,0A.748.748,0,0,1,118.711,228.3Z" transform="translate(-95.887 -206.141)" />
                  </svg>
                </div>
                <span  data-i18n="rating_unsatisfies">غير راضى</span>
              </div>
            </div>

            <div className="col-lg-2" style={{ textAlign: 'center' }} onClick={event => this.setDigitalHumanRating('1')}>
              <div className="rating" id="divDHRating_1">
                <div className="rating-svg">
                  <svg id="Component_1_1" data-name="Component 1 – 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                    <circle id="Ellipse_5" data-name="Ellipse 5" className="cls-1" cx="15" cy="15" r="15" />
                    <path id="Path_892" data-name="Path 892" className="cls-2" d="M100.488,130.244a2.244,2.244,0,1,0-2.244,2.244A2.247,2.247,0,0,0,100.488,130.244Zm-2.992,0a.748.748,0,1,1,.748.748A.749.749,0,0,1,97.5,130.244Z" transform="translate(-88.754 -117.795)" />
                    <path id="Path_894" data-name="Path 894" className="cls-2" d="M226.244,128a2.244,2.244,0,1,0,2.244,2.244A2.247,2.247,0,0,0,226.244,128Zm0,2.992a.748.748,0,1,1,.748-.748A.749.749,0,0,1,226.244,130.992Z" transform="translate(-205.549 -117.795)" />
                    <path id="Path_893" data-name="Path 893" className="cls-2" d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm0,28.7A13.7,13.7,0,1,1,28.7,15,13.711,13.711,0,0,1,15,28.7Z" />
                    <path id="Subtraction_2" data-name="Subtraction 2" className="cls-2" d="M1.325,6.044H0a7.176,7.176,0,0,1,14.17,0H12.845a5.87,5.87,0,0,0-11.52,0Z" transform="translate(7.915 16.958)" />
                  </svg>
                </div>
                <span  data-i18n="fully_unsatisfied">غير راضى تماما</span>
              </div>
            </div>
          </div>
          </div>
    }
          <br />
          <div className="row" id="unhappyReasonLabel" style={{ display: 'none' }}>
            <div className="col-lg-6" style={{ textAlign: 'right' }}>
              <div data-i18n="unsatisfaction_reason">فضلا اذكر سبب عدم رضاك عن الخدمة</div>
            </div>
          </div>
          <div className="row" id="unhappyReason" style={{ display: 'none' }}>
            <div className="col-lg-6" style={{ textAlign: 'center' }}>
              <textarea className="md-textarea form-control" id="unhappyReasonText" cols={80} rows={4} onChange={this.handleReasonChange}></textarea>
            </div>
          </div>

          <br />
          <div className="row">
            <div className="col-lg-6" style={{ textAlign: 'right' }}>
              <div data-i18n="notes_comments">ملاحظات واقتراحات</div>
            </div>
          </div>
          <div className="row" id="">
            <div className="col-lg-6" style={{ textAlign: 'center' }}>
              <textarea className="md-textarea form-control" id="commentText" cols={80} rows={4} onChange={this.handleCommentChange}></textarea>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-4"><button className="btn btn-success" onClick={this.saveUserFeedback}><span data-i18n="submit_btn">ارسال</span></button></div>
          </div>
        </div>


      </div>
    );
  }
}



const RatingWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <Rating />
      </PersistGate>
    </Provider>
  )
}



export default RatingWrapper;


