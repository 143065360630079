import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { setFullName, setMobile, setNin, setCommercialNumber, setServiceType, setToken } from '../core/actions/common';
import { connect } from 'react-redux';
import { State } from '../core/reducers';
import appconfig from './../appconfig.json';
import axios from 'axios';
import setLanguage, { translations } from 'translation';
//import { store } from "App";
import NafathModal from './NafathModal';
import { debounce } from '../hooks/useDebounce';

export interface CommonProps {
  serviceType: string;
  fullName: string;
  nin: string;
  mobile: string;
  commercialNumber: string;
  setServiceType(serviceType: string): void;
  setFullName(fullName: string): void;
  setNin(nin: string): void;
  setCommercialNumber(commercialNumber: string): void;
  setMobile(mobile: string): void;
  setToken(token: string): void;
}

class InputForm extends Component {
  constructor(public props: CommonProps) {
    super(props);
  }

  state = {
    nin: '',
    commercialNumber: '',
    serviceType: '',
    mobile: '',
    nafathOpen: false,
    nafathObject: {},
    loadingCheckStatus: false,
    urlAttributes: {},
    nafathErrorApi: false,
    nafathErrorMultipleCallingApi: false
  };
  /*  state = {
         language:"en",
         pageDirection: "ltr",
         textAlignment: "left",
     }
       */
  absherIntegrationStatus: boolean = true;
  componentDidMount(): void {
    /*  const languageSelector = document.getElementById("languageSelector") as HTMLInputElement;
         let language = languageSelector.value;
         
         if(language == "ar"){
             this.setState({
                 language:"ar",
                 pageDirection: "rlt",
                 textAlignment: "right"
             })
         }else{
             this.setState({
                 language:"en",
                 pageDirection: "ltr",
                 textAlignment: "left"
             })
         } */
    this.checkAbsherIntegration();
    setLanguage('ar');
  }
  checkAbsherIntegration = () => {
    this.absherIntegrationStatus = false;
    /*
       let url = appconfig.azure_reports_url + "/report/absherstatus";
        fetch(url).then(response => {
            return response.json();
        }).then(json => {
            console.log("absher statusresponse = " + json.status)
            if (json.status == "enabled") {
                this.absherIntegrationStatus = true;
            } else {
                this.absherIntegrationStatus = false;
            }
        }).catch(error => {
            alert("Fetch absher status error > " + error)
        }) 
*/
  };
  handleServiceChange = (serviceTy: string) => {
    this.setState({ serviceType: serviceTy });
    let serviceDescriptions = document.getElementById('serviceDescription') as HTMLElement;
    let serviceTitle = document.getElementById('serviceTitle') as HTMLElement;
    serviceDescriptions.innerHTML = '';

    let documentDescription = document.getElementById('documentDescription') as HTMLElement;
    let documentTitle = document.getElementById('documentTitle') as HTMLElement;

    if (serviceTy == '0') {
      serviceDescriptions.innerHTML = '';
      serviceTitle.innerHTML = '';
      documentDescription.innerHTML = '';
      documentTitle.innerHTML = '';
    }

    if (serviceTy == 'moi-absher') {
      serviceTitle.innerHTML = '';
      serviceTitle.innerHTML = 'تعريف بالخدمة';
      serviceDescriptions.innerHTML = '';
      serviceDescriptions.innerHTML =
        'تمكين المواطنين والمواطنات كبار السن والمرضى وذوي الإعاقة من التواصل مع المختصين في الأحوال المدنية من خلال اتصال فيديو عن بُعد لتفعيل أو تحديث حساباتهم في منصة أبشر  دون الحاجة لزيارة مراكز الأحوال المدنية';

      documentTitle.innerHTML = '';
      documentTitle.innerHTML = 'المتطلبات';
      documentDescription.innerHTML = '';
      documentDescription.innerHTML =
        '- تعبئة نموذج تفعيل أو تحديث حساب أبشر <br />-  إرفاق صورة من الهوية <br />- إرفاق ما يثبت أنه من الفئة المستهدفة';
    }
    if (serviceTy == 'moi-data') {
      serviceTitle.innerHTML = '';
      serviceTitle.innerHTML = 'تعريف بالخدمة';
      serviceDescriptions.innerHTML = '';
      serviceDescriptions.innerHTML =
        'تمكين جميع المستفيدين من التواصل مع المختصين في الأحوال المدنية من خلال اتصال فيديو عن بُعد لرفع الشكاوى أو الملاحظات والمقترحات من أماكن تواجدهم دون الحاجة لزيارة مراكز الأحوال المدنية';

      documentTitle.innerHTML = '';
      documentTitle.innerHTML = 'المتطلبات';
      documentDescription.innerHTML = '';
      documentDescription.innerHTML =
        '- إرفاق صورة من الخلل الظاهر في منصة أبشر أو التطبيق <br />- توفير الوثائق بناء على حالة ومتطلب المستفيد';
    }
    if (serviceTy == 'moi-compliant') {
      serviceTitle.innerHTML = '';
      serviceTitle.innerHTML = 'تعريف بالخدمة';
      serviceDescriptions.innerHTML = '';
      serviceDescriptions.innerHTML =
        'تمكين جميع المستفيدين من التواصل مع المختصين في الأحوال المدنية من خلال اتصال فيديو عن بُعد لرفع الشكاوى أو الملاحظات والمقترحات  من أماكن تواجدهم دون الحاجة لزيارة مراكز الأحوال المدنية';

      documentTitle.innerHTML = '';
      documentTitle.innerHTML = '';
      documentDescription.innerHTML = '';
      documentDescription.innerHTML = '';
    }
  };

  openWelcomeScreen = async () => {
    const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
    let language = languageSelector.value;
    let entity = document.getElementById('entity') as HTMLInputElement;

    let nin = document.getElementById('nin') as HTMLInputElement;
    let name = document.getElementById('fullname') as HTMLInputElement;
    let mobile = document.getElementById('mobile') as HTMLInputElement;
    let email = document.getElementById('email') as HTMLInputElement;
    let ninRequiredMsg = document.getElementById('ninRequiredMsg') as HTMLElement;
    let nameRequiredMsg = document.getElementById('nameRequiredMsg') as HTMLElement;
    let mobileRequiredMsg = document.getElementById('mobileRequiredMsg') as HTMLElement;
    let emailRequiredMsg = document.getElementById('emailRequiredMsg') as HTMLElement;
    let securityVonfirmRequiredMsg = document.getElementById('securityVonfirmRequiredMsg') as HTMLElement;
    let entityRequiredMsg = document.getElementById('entityRequiredMsg') as HTMLElement;
    var numbersExp = /^[0-9]+$/;
    let validInputs = true;
    let nmaeRegex = /[a-zA-Z\u0600-\u06FF]+\d*/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Example usage
    const isValidEmail = emailRegex.test(email.value);

    if (nin.value != '') {
      if (
        !nin.value.match(numbersExp) ||
        nin.value.length != 10 ||
        !(nin.value.startsWith('1') || nin.value.startsWith('2'))
      ) {
        //ninRequiredMsg.innerHTML = "ية غير صحيح";
        ninRequiredMsg.innerHTML = translations[language]['validation_invalid_nin'];
        ninRequiredMsg.style.display = '';
        validInputs = false;
      } else {
        ninRequiredMsg.style.display = 'none';
      }
    }

    if (nin.value === '') {
      ninRequiredMsg.style.display = '';
      validInputs = false;
    } else {
      ninRequiredMsg.style.display = 'none';
    }

    if (entity.value === '0') {
      entityRequiredMsg.style.display = '';
      validInputs = false;
    } else {
      entityRequiredMsg.style.display = 'none';
    }

    if (name.value == '') {
      nameRequiredMsg.style.display = '';
      validInputs = false;
    } else if (nmaeRegex.test(name.value)) {
      nameRequiredMsg.style.display = 'none';
    } else {
      nameRequiredMsg.style.display = '';
      nameRequiredMsg.textContent = translations[language]['validation_name'];
      validInputs = false;
    }

    if (mobile.value == '') {
      mobileRequiredMsg.style.display = '';
      mobile.focus();
      validInputs = false;
    } else {
      mobileRequiredMsg.style.display = 'none';
    }

    if (mobile.value != '') {
      if (!mobile.value.match(numbersExp) || mobile.value.length != 10) {
        //mobileRequiredMsg.innerHTML = "يجب ان يكون رقم الجوال مكون من ١٠ ارقام";
        mobileRequiredMsg.innerHTML = translations[language]['validation_invalid_mobile'];
        mobileRequiredMsg.style.display = '';
        mobile.focus();
        validInputs = false;
      } else {
        mobileRequiredMsg.style.display = 'none';
      }
    }

    if (email.value != '') {
      if (isValidEmail == false) {
        emailRequiredMsg.style.display = '';
        email.focus();
        validInputs = false;
      } else {
        emailRequiredMsg.style.display = 'none';
      }
    }
    let securityConfirm = document.getElementById('securityConfirm') as HTMLInputElement;
    let submitbtn = document.getElementById('submitbtn') as HTMLButtonElement;
    if (securityConfirm.checked == false) {
      submitbtn.style.backgroundColor = '#006937';
      securityVonfirmRequiredMsg.style.display = '';
      validInputs = false;
    } else {
      securityVonfirmRequiredMsg.style.display = 'none';
    }
    this.props.setFullName(name.value);
    this.props.setNin(nin.value);
    this.props.setServiceType(entity.value);
    this.props.setMobile(mobile.value);
    localStorage.setItem('emailAddress', email.value);

    // alert(this.absherIntegrationStatus);
    if (validInputs != false) {
      // HERE
      if (this.state.serviceType == 'moi-absher') {
        window.location.href =
          '?isPublic=true&config=true&fullname=' +
          name.value +
          '&nin=' +
          nin.value +
          '&mobile=' +
          mobile.value +
          '&serviceType=' +
          entity.value;
          return;
      }

      let responseBack: any = await this.sendToNafath(nin.value);
      if (responseBack && responseBack.status === 200) {
        if (responseBack?.data?.random) {
          this.setState({ nafathErrorApi: false });
          this.setState({ nafathObject: { ...responseBack.data, id: Number(nin.value) } });
          this.setState({
            urlAttributes: { fullname: name.value, nin: nin.value, mobile: mobile.value, serviceType: entity.value }
          });
          this.setState({ nafathOpen: true });
        } else {
          this.setState({ nafathErrorMultipleCallingApi: true });
          setTimeout(() => {
            this.setState({ nafathErrorMultipleCallingApi: false });
          }, 6000);
        }
      } else {
        this.setState({ nafathErrorApi: true });
      }
      //window.location.href = "?isPublic=true&config=true&fullname=" + name.value + "&nin=" + nin.value + "&mobile=" + mobile.value + "&serviceType=" + entity.value;
    }
    ///return true;
  };

  handleCloseNafathModal = () => {
    this.setState({ nafathOpen: false });
    this.setState({ nafathObject: {} });
  };

  sendToNafath = async (nin: any) => {
    this.setState({ nafathErrorApi: false });
    let api_url = appconfig.azure_nafath + `/api/v1/endakNafath/moi/login`;

    // appconfig.azure_reports_url + "/api/sendcustomerotp";
    const body = {
      id: Number(nin)
    };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let responseApi;

    await axios
      .post(api_url, body, options)
      .then((response) => {
        console.log('send otp response = ' + response);
        responseApi = { ...response };
      })
      .catch((err) => {
        //submitButtonSpinner.style.display = 'none';
        // submitbtn.disabled = false;
        console.log(err);
        responseApi = err;

        //alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
      });
    console.log('responseapi', responseApi);
    return responseApi;
  };

  sendOtp = () => {
    let nin = document.getElementById('nin') as HTMLInputElement;
    let name = document.getElementById('fullname') as HTMLInputElement;
    let mobile = document.getElementById('mobile') as HTMLInputElement;
    let submitbtn = document.getElementById('submitbtn') as HTMLInputElement;
    let submitButtonSpinner = document.getElementById('submitButtonSpinner') as HTMLInputElement;

    submitButtonSpinner.style.display = '';
    submitbtn.disabled = true;

    let otp_url = appconfig.azure_reports_url + '/api/sendcustomerotp';
    const body = {
      nin: nin.value,
      mobile: mobile.value,
      fullname: name.value
    };

    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios
      .post(otp_url, body, options)
      .then((response) => {
        console.log('send otp response = ' + response);
        //alert(JSON.stringify(response));
        if (response.data.successMessage) {
          const urlParams = new URLSearchParams(window.location.search);
          const client = urlParams.get('client');
          window.location.href = 'otp?isPublic=true&config=true&client=' + client;
        } else {
          submitButtonSpinner.style.display = 'none';
          submitbtn.disabled = false;
          //alert("Failed to send otp");
          alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
        }
      })
      .catch((err) => {
        submitButtonSpinner.style.display = 'none';
        submitbtn.disabled = false;
        console.log(err);
        alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
      });
  };

  checkNinOnlyDigits = (event: any) => {
    const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
    let language = languageSelector.value;
    let ninRequiredMsg = document.getElementById('ninRequiredMsg') as HTMLElement;
    const re = /^[0-9\b]+$/;
    //const re = /^[\u0660-\u0669\u06F0-\u06F90-9]/g;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ nin: event.target.value });
      ninRequiredMsg.style.display = 'none';
    } else {
      ninRequiredMsg.style.display = '';
      ninRequiredMsg.innerHTML = translations[language]['validation_only_digits']; //" غير مسموح باستخدام الحروف";
    }
  };

  checkMobileOnlyDigits = (event: any) => {
    const languageSelector = document.getElementById('languageSelector') as HTMLInputElement;
    let language = languageSelector.value;
    let mobileRequiredMsg = document.getElementById('mobileRequiredMsg') as HTMLElement;
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
      this.setState({ mobile: event.target.value });
      mobileRequiredMsg.style.display = 'none';
    } else {
      mobileRequiredMsg.style.display = '';
      mobileRequiredMsg.innerHTML = translations[language]['validation_only_digits']; //" غير مسموح باستخدام الحروف";
    }
  };
  render() {
    return (
      <div className="content-container" id="content-container">
        <div className="row">
          <div className="col-md-5 Rside">
            <div className="row">
              <div className="t2">
                فرعنا الإلكتروني <span className="t1">عندك</span>
              </div>
            </div>
            <div className="row">
              <span className="p1 p2">
                تتيح هذه الخدمة إمكانية عقد <b>زيارة افتراضية </b>والتواصل مع موظف خدمة العملاء عبر الاتصال المرئي بكل
                سهولة ويسر
              </span>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="t2">
                <span className="t2" id="serviceTitle"></span>
              </div>
            </div>
            <div className="row">
              <span className="p1 p2" id="serviceDescription"></span>
            </div>
            <br />
            <br />
            <div className="row">
              <div className="t2">
                <span className="t2" id="documentTitle"></span>
              </div>
            </div>
            <div className="row">
              <span className="p1 p2" id="documentDescription"></span>
            </div>
          </div>
          <div className="col-md-7">
            <div className="leftSide">
              <div className="row">
                <div className="t1" data-i18n="start_from_here">
                  إبدأ من هنا
                </div>
              </div>

              <div className="row">
                <span className="mandatoryfield">*</span>
                &nbsp;&nbsp;
                <span className="label1" data-i18n="service_type">
                  نوع الخدمة{' '}
                </span>
              </div>

              <div className="row">
                <select
                  className="form-control"
                  name="serviceType"
                  id="entity"
                  onChange={(e) => this.handleServiceChange(e.target.value)}
                >
                  <option value="0" data-i18n="select">
                    اختر
                  </option>
                  <option value="moi-absher">تحديث معلومات حساب أبشر</option>
                  <option value="moi-data">جودة البيانات</option>
                  <option value="moi-compliant">الملاحظات والمقترحات</option>
                </select>
              </div>
              <div className="row">
                <span id="entityRequiredMsg" style={{ color: 'red', display: 'none', fontSize: '11px' }}>
                  {' '}
                  هذا الحقل مطلوب{' '}
                </span>
              </div>
              <br />
              <br />
              <div className="row" id="nin_row_1">
                <span className="mandatoryfield">*</span>
                &nbsp;&nbsp;
                <span className="label1" data-i18n="nin_residence">
                  {' '}
                  الهوية الوطنية / الاقامة
                </span>
              </div>

              <div className="row" id="nin_row_2">
                <input
                  className="form-control"
                  maxLength={10}
                  type="text"
                  data-i18n="nin_residence_placeholder"
                  onChange={this.checkNinOnlyDigits}
                  value={this.state.nin}
                  placeholder="الهوية الوطنية / الاقامة"
                  name="nin"
                  id="nin"
                />
              </div>
              <div className="row" id="nin_row_3">
                <span
                  id="ninRequiredMsg"
                  style={{ color: 'red', display: 'none', fontSize: '11px' }}
                  data-i18n="required_field"
                >
                  هذا الحقل مطلوب
                </span>
              </div>
              <br></br>
              <div className="row">
                <span className="mandatoryfield">*</span>
                &nbsp;&nbsp;
                <span className="label1" data-i18n="name">
                  {' '}
                  الاسم
                </span>
              </div>

              <div className="row">
                <input
                  className="form-control"
                  type="text"
                  data-i18n="name_placeholder"
                  placeholder="الاسم"
                  name="fullname"
                  id="fullname"
                />
              </div>
              <div className="row">
                <span
                  id="nameRequiredMsg"
                  style={{ color: 'red', display: 'none', fontSize: '11px' }}
                  data-i18n="required_field"
                >
                  هذا الحقل مطلوب
                </span>
              </div>
              <br></br>

              <div className="row">
                <span className="mandatoryfield">*</span>
                &nbsp;&nbsp;
                <span className="label1" data-i18n="mobile">
                  رقم الجوال{' '}
                </span>
              </div>

              <div className="row">
                <input
                  className="form-control"
                  value={this.state.mobile}
                  style={{ fontFamily: 'tahoma !important' }}
                  maxLength={10}
                  type="text"
                  data-i18n="mobile_placeholder"
                  onChange={this.checkMobileOnlyDigits}
                  placeholder="رقم الجوال"
                  name="mobile"
                  id="mobile"
                />
              </div>
              <div className="row">
                <span
                  id="mobileRequiredMsg"
                  style={{ color: 'red', display: 'none', fontSize: '11px' }}
                  data-i18n="required_field"
                >
                  هذا الحقل مطلوب
                </span>
              </div>
              <br />
              <br />
              <div className="row">
                {/*<span className="mandatoryfield">*</span>*/}
                &nbsp;&nbsp;
                <span className="label1" data-i18n="email">
                  الإيميل
                </span>
              </div>

              <div className="row">
                <input
                  className="form-control"
                  style={{ fontFamily: 'tahoma !important' }}
                  type="email"
                  data-i18n="email_placeholder"
                  placeholder="الإيميل"
                  name="email"
                  id="email"
                />
              </div>
              <div className="row">
                <span
                  id="emailRequiredMsg"
                  style={{ color: 'red', display: 'none', fontSize: '11px' }}
                  data-i18n="validation_email"
                >
                  الإيميل غير صحيح
                </span>
              </div>
              <br />
              <br />
              <div className="row">
                <div className="col-md-12">
                  <span className="mandatoryfield">*</span>
                  &nbsp;&nbsp;
                  <input type="checkbox" name="securityConfirm" id="securityConfirm" value="confirmed" />
                  <span data-i18n="confirmation_text" id="confirmation_text">
                    {' '}
                    أتعهد انا مستخدم هذه الخدمة بعدم تسجيل هذه المكالمة وأن احافظ على سرية البيانات الواردة فيها
                    التزاماً بكافة الأحكام والقواعد الواردة في نظام مكافحة الجرائم المعلوماتية الصادر بالمرسوم الملكي
                    رقم م/17 وتاريخ 1428/03/08, وبجميع الأنظمة ذات الصلة{' '}
                  </span>
                </div>
              </div>
              <div className="row">
                <span
                  id="securityVonfirmRequiredMsg"
                  style={{ color: 'red', display: 'none', fontSize: '11px' }}
                  data-i18n="confirmation_approval"
                >
                  الرجاء الموافقه على الإقرار{' '}
                </span>
              </div>
              <br></br>
              <div className="row">
                <button className="btn btn-primary" id="submitbtn" onClick={debounce(this.openWelcomeScreen, 100)}>
                  <span data-i18n="submit_btn">استمرار</span>
                  &nbsp;&nbsp;
                  <span
                    id="submitButtonSpinner"
                    style={{ display: 'none' }}
                    className="spinner-border spinner-border-sm"
                  ></span>
                </button>
              </div>
              {this.state.nafathErrorApi && (
                <span
                  className="label1"
                  data-i18n="nafathErrorApiMessg"
                  style={{ display: 'block', color: 'red', width: '100%', marginTop: '10px' }}
                >
                  {' '}
                  يوجد خطأ يرجي طلب المساعدة{' '}
                </span>
              )}
              {this.state.nafathErrorMultipleCallingApi && (
                <span className="label1" style={{ display: 'block', color: 'red', width: '100%', marginTop: '10px' }}>
                  يرجى المحاولة مرة أخرى{' '}
                </span>
              )}
            </div>
          </div>
        </div>
        {this.state.nafathOpen && (
          <>
            <NafathModal
              isOpen={this.state.nafathOpen}
              onClose={this.handleCloseNafathModal}
              data={this.state.nafathObject}
              urlAttributes={this.state.urlAttributes}
            />
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: State, props: CommonProps) => ({
  serviceType: state.common.serviceType
});

const mapDispatchToProps = (dispatch: any, props: CommonProps) => ({
  setServiceType: (serviceType: string) => dispatch(setServiceType(serviceType)),
  setFullName: (fullName: string) => dispatch(setFullName(fullName)),
  setNin: (nin: string) => dispatch(setNin(nin)),
  setCommercialNumber: (commercialNumber: string) => dispatch(setCommercialNumber(commercialNumber)),
  setMobile: (mobile: string) => dispatch(setMobile(mobile)),
  setToken: (token: string) => dispatch(setToken(token))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(InputForm);
