import React, { Component } from "react";
import appconfig from "./../appconfig.json";
import axios from 'axios';


import { Provider } from 'react-redux';
import { store, persistedStore } from "App";
import { PersistGate } from "redux-persist/integration/react";

import { setToken } from "core/actions/common";

class OTPInput extends Component {

  common = store.getState().common;

  nin = this.common.nin;
  name = this.common.fullName;
  mobile = this.common.mobile;
  serviceType = this.common.serviceType;


  verifyOtp = () => {
    let otp = document.getElementById('otp') as HTMLInputElement;
    if(otp.value == ''){
      alert("برجاء ادخال رمز التحقق")
      return false;
    }
    let otp_url = appconfig.azure_reports_url + "/api/verifyotp";
    const body = {
      "nin": this.nin,
      "otp": otp.value
    };
    const options = {
      headers: {
          "Content-Type" : "application/json"
      }
    };
    axios.post(otp_url, body , options).then(response => {
      console.log("Verify otp response = " + response);
      let message = response.data.Message;
     // alert(JSON.stringify(response));
      if (message == false) {
        alert("رمز التحقق غير صحيح");
      } else {
        store.dispatch(setToken(response.data.token));
        const urlParams = new URLSearchParams(window.location.search);
        const client = urlParams.get('client');
        window.location.href = "/?isPublic=true&config=true&fullname=" + this.name + "&nin=" + this.nin + "&mobile=" + this.mobile + "&serviceType=" + this.serviceType+ "&client=" + client
      }


    }).catch(err => {
      console.log(err);
     // alert(err);
      alert("رمز التحقق غير صحيح");   
    });
    return true;
  }


  resendOtp = () => {
     
    let submitbtn = document.getElementById('resendOtp') as HTMLInputElement;
    let submitButtonSpinner = document.getElementById('submitButtonSpinner') as HTMLInputElement;
    
    submitButtonSpinner.style.display = '';
    submitbtn.disabled = true;
    
    let otp_url = appconfig.azure_url + "/api/sendcustomerotp";
    const body = {
        "nin": this.nin,
        "mobile": this.mobile,
        "fullname": this.name
    };

    const options = {
        headers: {
            "Content-Type" : "application/json"
        }
      };

    axios.post(otp_url, body , options).then(response => {
        console.log("send otp response = " + response);
        //alert(JSON.stringify(response));
        if (response.data.successMessage) {
            window.location.href = "otp?isPublic=true&config=true";
        } else {
           submitButtonSpinner.style.display = 'none';
            submitbtn.disabled = false;
            //alert("Failed to send otp");
            alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
        }
    }).catch(err => {
        submitButtonSpinner.style.display = 'none';
        submitbtn.disabled = false;
        console.log(err);
        alert('تعذر ارسال رمز التحقق، الرجاء المحاولة في وقت لاحق');
    });
  }

  render() {

    return (
      <div className="content-container">
        <div className='row' dir='rtl' style={{ textAlign: "right" }}>

          <div className='col-md-5 Rside'>
            <div className='row'>
              <div className='t2' data-i18n="our_branch">فرعنا الإلكتروني <span className='t1' data-i18n="application_name">عندك</span></div>
            </div>
            <div className='row'>
              <span className='p1 p2' data-i18n="message">تتيح هذه الخدمة إمكانية عقد <b>زيارة افتراضية </b>والتواصل مع موظف خدمة العملاء عبر الاتصال المرئي بكل سهولة ويسر</span>
            </div>
          </div>


          <div className='col-md-7'>
            <div className='leftSide'>
              <div className='row'>
                <div className='t1' data-i18n="start_from_here">إبدأ من هنا</div>
              </div>

              <div className='row'>
                <div className='p1 p2' data-i18n="otp_header">  
                    أدخل رمز التحقق المرسل على رقم الجوال المسجل في ابشر
                    </div>
              </div>

{/* 
            <br></br>
              <div className='row'>
                <span className='label1'>الجوال    </span>
              </div>


              <div className='row'>
                <span>{this.mobile?.substring(this.mobile.length-4,this.mobile.length)+"******"} </span>
              </div> */}

              <br></br>
              <div className='row'>
                <span className="mandatoryfield">*</span>
                <span className='label1' data-i18n="otp_label">رمز التحقق  </span>
              </div>

              <div className='row'>
                <input className='form-control' maxLength={4} type="text" data-i18n="otp_label" placeholder='رمز التحقق' name='otp' id='otp' />
              </div>

              <br></br>
              <div className='row'>
                <button className="btn btn-success btn1" id="submitbtn" onClick={this.verifyOtp}><span data-i18n="submit_btn">استمرار</span></button>
                  &nbsp;&nbsp;
                <button className="btn btn-success btn1" id="resendOtp" onClick={this.resendOtp}><span data-i18n="resend_otp_btn">اعادة ارسال رمز التحقق,</span>
                      &nbsp;&nbsp;<span id="submitButtonSpinner" style={{display:"none"}} className="spinner-border spinner-border-sm"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


}

const OTPWrapper = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <OTPInput />
      </PersistGate>
    </Provider>
  )
}



export default OTPWrapper;



