import React , { Component } from "react"
import 'bootstrap/dist/css/bootstrap.css';
 
class AfterRating extends Component{
  queryParams = new URLSearchParams(window.location.search);
     isPublic = this.queryParams.get("isPublic");
    render(){
      let className = "";
    if(this.isPublic == "true"){
      className = "main-container";
    }else{
      className = "private-container";
    }
      return(
        <div id="main">
          <div className={className}>
            <div className="row">
              <div className="col-md-8">
                  <h1 className="t2 geFOnt" data-i18n="thanksMsgL1">شكرا لمشاركتكم</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                          <h1 className="t2 geFOnt" data-i18n="thanksMsgL2">شكرا لإسهامكم معنا في تطوير خدماتنا   </h1>
              </div>
            </div>
            <a className="btn btn-primary" href="https://moi-endak-frontend-test.azurewebsites.net/?isPublic=true"> العودة  إلى بداية الخدمة</a>

          </div>
        </div>
      );
    }
}
export default AfterRating;