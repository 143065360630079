import React, { useState, useEffect } from 'react';
// import 'bootstrap/dist/css/bootstrap.css';
import appconfig from './../appconfig.json';
import axios from 'axios';
import setLanguage from 'translation';
import { utils } from 'Utils/Utils';
//import { store } from "App";

interface props {
  isOpen: any;
  onClose: any;
  data: any;
  urlAttributes: any;
}

const NafathModal = ({ isOpen, onClose, data, urlAttributes }: props) => {
  const [, setLoading] = useState(false);

  const [waitingStatus, setWaitingStatus] = useState(false);

  const [rejectedStatus, setRejectedStatus] = useState(false);

  const [errorApis, setErrorApis] = useState(false);

  const [errorinNafathResponse, setErrorinNafathResponse] = useState(false);

  const [errorinUserData, setErrorinUserData] = useState(false);

  const [maxTriesNumber, setMaxTriesNumber] = useState(false);

  const fetchRequest = async () => {
    setLoading(true);

    const api_url = appconfig.azure_nafath + `/api/v1/endakNafath/moi/checkRequestStatus`;
    const options = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    let responseApi: any;
    try {
      const maxRetries = 1; // Set the maximum number of retries

      for (let retry = 0; retry < maxRetries; retry++) {
        // Make the API request
        const response = await axios.post(api_url, data, options);
        responseApi = {...response} ;

        if (responseApi.data.status === 'APPROVED') {
          if (responseApi?.data?.person?.arabicName && urlAttributes?.fullname && utils.isArabic(urlAttributes?.fullname)) {
            if(utils.getFirstWord(responseApi?.data?.person?.arabicName) !== utils.getFirstWord(urlAttributes?.fullname)){
              setErrorinUserData(true)
              setLoading(false)
              break;
            }
          }
          if (responseApi?.data?.person?.englishName && urlAttributes?.fullname && !utils.isArabic(urlAttributes?.fullname)) {
            if(utils.getFirstWord(responseApi?.data?.person?.englishName) !== utils.getFirstWord((urlAttributes?.fullname as string).toUpperCase())){
              setErrorinUserData(true)
              setLoading(false)
              break;
            }            
          }
          setWaitingStatus(false);
          
          setRejectedStatus(true);
          setMaxTriesNumber(false);
          onClose();

          window.location.href =
            '?isPublic=true&config=true&fullname=' +
            urlAttributes.fullname +
            '&nin=' +
            urlAttributes.nin +
            '&mobile=' +
            urlAttributes.mobile +
            '&serviceType=' +
            urlAttributes.serviceType;

          break; // Exit the loop if status is "APPROVED"
        } else if (responseApi.data.status === 'WAITING') {
          setWaitingStatus(true);
         
          setRejectedStatus(false);
          setMaxTriesNumber(false);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          // setTimeout(function(){
          //     onClose();
          // }, 6000);
        } else if (responseApi.data.status === 'REJECTED') {
          setWaitingStatus(false);
          
          setRejectedStatus(true);
          setMaxTriesNumber(false);
          // setTimeout(function(){
          break;
        }
      }
    } catch (error) {
      setErrorApis(true);
      setLoading(false);
      setTimeout(function () {
        onClose();
        setErrorApis(false);
      }, 5000);
      console.error('Error fetching status:', error);

      console.error('responseApis:', responseApi);
      // Handle error (e.g., show an error message)
    }



    if (responseApi?.data.status === 'WAITING') {
      setWaitingStatus(false);
      setMaxTriesNumber(true);
      setTimeout(function () {
        setLoading(false);
        onClose();
      }, 5000);
      console.log(`waiting screen setimeout`);
    } else if (responseApi?.data.status === 'REJECTED') {
      setErrorinNafathResponse(true);
      setLoading(false);

      console.log(`rejected screen setimeout`);
    }
  };

  useEffect(() => {
    const language = localStorage.getItem('lang');
    setLanguage(language);
    if (data?.random) {
      fetchRequest();
    }
    return () => {
      setErrorApis(false);
      setErrorinNafathResponse(false);
    };
  }, [data]);

  //children
  if (!isOpen) return null;
  console.log(data);

  const closeButtonOnNafathError = () => {
    onClose();
    setErrorinNafathResponse(false);
    setErrorinUserData(false);
  };

  return (
    <div
      // onClick={onClose}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          background: 'white',
          // height: 210,
          // width: 325,
          margin: 'auto',
          padding: '2%',

          borderRadius: '10px',
          boxShadow: '2px solid black',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <div className="container-popuop">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <div
              style={{
                width: '100%',
                maxWidth: '100%',
                textAlign: 'center'
              }}
            >
              <h6>
                {!rejectedStatus && !waitingStatus && !errorApis && !errorinUserData && (
                  <span data-i18n="confirmationNafath">التأكيد عن طريق نفاذ </span>
                )}

                {/* {rejectedStatus && <span data-i18n="verificationRejected"> الرجاء اختيار رمز التحقق الصحيح </span>} */}
                {waitingStatus && <span data-i18n="underVerification"> قيد التحقق </span>}

                {maxTriesNumber && <span data-i18n="maxTriesNumberMessg"> انتهى الوقت. الرجاء المحاولة مرة أخرى </span>}

                {errorApis && <span data-i18n="errorApi">يوجد عطل برجاء المحاولة مرة أخري </span>}
                {rejectedStatus || errorinNafathResponse ? (
                  <span>
 يرجى المحاولة مرة أخرى                     </span>
                ) : null}
              </h6>
              {!errorApis && !errorinNafathResponse  && !errorinUserData && (
                <span
                  style={{
                    width: '40px',
                    height: '40px',
                    border: '1px solid #2B2969',
                    borderRadius: '5px',
                    padding: '12px',
                    fontSize: '16px',
                    display: 'block',
                    margin: 'auto',
                    lineHeight: 'initial'
                  }}
                  className="english-number"
                >
                  {data.random}
                </span>
              )}

              {!rejectedStatus && !waitingStatus && !errorApis && !errorinNafathResponse && !errorinUserData && (
                <span
                  style={{
                    fontSize: '12px'
                  }}
                  data-i18n="openNafath"
                >
                  {' '}
                  الرجاء فتح تطبيق نفاذ وتأكيد الرقم أعلاه{' '}
                </span>
              )}
              {waitingStatus && (
                <span data-i18n="updateVerification"> يمكنك تحديث التحقق من خلال التأكيد مرة اخري</span>
              )}
               {errorinUserData && (
                <span>الرجاء إدخال المعلومات الشخصية الصحيحة</span>
              )}
            </div>
          </div>

          <div
            style={{
              width: '100%',
              maxWidth: '100%',
              marginTop: '10px'
            }}
          >
            {errorinNafathResponse || errorinUserData ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <button
                  style={{ backgroundColor: '#2B2969', margin: '0px 6px' }}
                  className="btn btn-primary"
                  onClick={closeButtonOnNafathError}
                >
                  <span>موافق</span>
                </button>
                {/*
                            <button style={{backgroundColor: "#2B2969"}} className="btn btn-primary" onClick={() => onClose() }>
                                <span data-i18n="closeBtn">إلغاء </span>  
                            </button> */}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NafathModal;
