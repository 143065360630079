/* eslint-disable react/display-name */
// © Microsoft Corporation. All rights reserved.
import React, { useEffect } from 'react';
import { Separator, Pivot, PivotItem, Stack } from '@fluentui/react';
import { Call, LocalVideoStream, VideoDeviceInfo } from '@azure/communication-calling';
import MediaControls from './MediaControls';
import { CommandPanelTypes } from './CommandPanel';
//import {  SettingsIcon } from '@fluentui/react-icons-northstar';
import { Constants } from 'core/constants';
import appconfig from "./../appconfig.json";
import { utils } from '../Utils/Utils';
import {
  headerContainer,
  pivotItemStyles,
  separatorContainerStyle,
  separatorStyles,
 // pivotItemStyle,
  headerCenteredContainer
} from './styles/Header.styles';
import { ParticipantStream } from 'core/reducers';

export interface HeaderProps {
  selectedPane: CommandPanelTypes;
  setSelectedPane: any;
  endCallHandler(): void;
  actionable: boolean;
  localVideo: boolean;
  mic: boolean;
  shareScreen: boolean;
  call: Call;
  endCall(): void;
  screenShareStreams: ParticipantStream[];
  activeScreenShareStream: ParticipantStream | undefined;
  localVideoRendererIsBusy: boolean;
  cameraPermission: string;
  microphonePermission: string;
  screenWidth: number;
  setMic(mic: boolean): void;
  setLocalVideoStream(localVideoStream: LocalVideoStream | undefined): void;
  setScreenShare(screenShare: boolean): void;
  isLocalScreenShareSupportedInBrowser(): boolean;
  localVideoStream: LocalVideoStream | undefined;
  videoDeviceInfo: VideoDeviceInfo | undefined;
  callState: string;
  receivedChatMessage:string;
}

export default (props: HeaderProps): JSX.Element => {
  //props.setMic(true);
  const togglePeople = (selectedPane: string, setSelectedPane: (pane: string) => void) => {
    return selectedPane !== CommandPanelTypes.People
      ? setSelectedPane(CommandPanelTypes.People)
      : setSelectedPane(CommandPanelTypes.None);
  };

  const toggleOptions = (selectedPane: string, setSelectedPane: (pane: string) => void) => {
    return selectedPane !== CommandPanelTypes.Settings
      ? setSelectedPane(CommandPanelTypes.Settings)
      : setSelectedPane(CommandPanelTypes.None);
  };

  interface EndCallAPIResponse {
    attachmentsLink: string;
    id: string;
  }
  const utility = {
    endCall: async (): Promise<EndCallAPIResponse> => {

      const urlParams = new URLSearchParams(window.location.search);
      const meetingID = urlParams.get('meetingID');  

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    }

    const url = appconfig.azure_url+"/api/customer-meeting/end/"+meetingID;
     console.log('End Call URL >>'+url);
      const response = await fetch(url , requestOptions);
      if (response.ok) {
        return response.json();
        
      }else{
        throw new Error('Invalid url response');
      }
  } 
}

  const handleLocalVideoOnOff = async () => {
    if (props.localVideoStream) {
      await props.call.stopVideo(props.localVideoStream);
      props.setLocalVideoStream(undefined);
      utils.registerEvent("vedio switched off");
    } else {
      if (props.videoDeviceInfo) {
        const localVideoStream = new LocalVideoStream(props.videoDeviceInfo);
        props.setLocalVideoStream(localVideoStream);
        await props.call.startVideo(localVideoStream);
        utils.registerEvent("vedio switched on");
      }
    }    
  };

  const handleMuteOnOff = () => {
   // alert(props.mic);
    props.setMic(!props.mic);
  };

  const handleScreenSharingOnOff = () => {
    props.setScreenShare(!props.shareScreen);
  };

  

  useEffect(() => {
    if (props.call && props.call.localVideoStreams.length === 0 && props.localVideoStream) {
      props.call.startVideo(props.localVideoStream);
    }
  }, [props.call, props.localVideoStream]);
 let hiddenStyle = {display:'none'};
 let defaultStyle = {display:''};
  return (
    <Stack id="header" style={props.callState === Constants.CONNECTED ? defaultStyle : hiddenStyle} className={props.screenWidth > Constants.MINI_HEADER_WINDOW_WIDTH ? headerContainer : headerCenteredContainer}>
      
      <Pivot
        onKeyDownCapture={(e) => {
          if ((e.target as HTMLElement).id === CommandPanelTypes.People && e.keyCode === 39) e.preventDefault();
        }}
        getTabId={(itemKey: string) => itemKey}
        onLinkClick={(item) => {
          if (!item) return;
          if (item.props.itemKey === CommandPanelTypes.Settings)
            toggleOptions(props.selectedPane, props.setSelectedPane);
          if (item.props.itemKey === CommandPanelTypes.People) togglePeople(props.selectedPane, props.setSelectedPane);
        }}
        styles={pivotItemStyles}
       // initialSelectedKey={CommandPanelTypes.None}
        selectedKey={props.selectedPane}
      >

         {/*
        <PivotItem
          itemKey={CommandPanelTypes.Settings}
          onRenderItemLink={() => (
            <SettingsIcon
              outline={props.selectedPane === CommandPanelTypes.Settings ? false : true}
              size="medium"
              className={pivotItemStyle}
            />
          )}
        />
       
        <PivotItem
          itemKey={CommandPanelTypes.People}
          onRenderItemLink={() => (
            <UserFriendsIcon
              outline={props.selectedPane === CommandPanelTypes.People ? false : true}
              size="medium"
              className={pivotItemStyle}
            />
          )}
        />
          */}
        <PivotItem itemKey={CommandPanelTypes.None} />
      </Pivot>
      {props.screenWidth > Constants.MINI_HEADER_WINDOW_WIDTH && (
        <div className={separatorContainerStyle}>
          <Separator styles={separatorStyles} vertical={true} />
        </div>
      )}
      <MediaControls
        micActive={props.mic}
        onMicChange={handleMuteOnOff}
        cameraActive={props.localVideoStream !== undefined}
        onCameraChange={handleLocalVideoOnOff}
        screenShareActive={props.shareScreen}
        activeScreenShareStream={props.screenShareStreams[0] ?? undefined}
        onScreenShareChange={handleScreenSharingOnOff}
        onEndCallClick={async () => {
          localStorage.removeItem("url");
          if (props.localVideo)
            handleLocalVideoOnOff();
          props.endCall();
          let response: EndCallAPIResponse = await utility.endCall();
          console.log('End Call API Response = ' + response.id);
        } }
        cameraPermission={props.cameraPermission}
        microphonePermission={props.microphonePermission}
        localVideoRendererIsBusy={props.localVideoRendererIsBusy}
        compressedMode={props.screenWidth <= Constants.MINI_HEADER_WINDOW_WIDTH}
        isLocalScreenShareSupportedInBrowser={props.isLocalScreenShareSupportedInBrowser} 
        selectedPane= {props.selectedPane}
        setSelectedPane={props.setSelectedPane} 
        callState = {props.callState}
        receivedChatMessage = {props.receivedChatMessage}
                     />
    </Stack>
  );
};
