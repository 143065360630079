// © Microsoft Corporation. All rights reserved.
import { AudioDeviceInfo, VideoDeviceInfo, RemoteVideoStream } from '@azure/communication-calling';
import axios from 'axios';
import { CommunicationIdentifierKind } from '@azure/communication-common';
import { CommunicationUserToken } from '@azure/communication-identity';
import preval from 'preval.macro';
import appconfig from './../appconfig.json';
import { store } from 'App';
export interface MeetingLink {
  meetingLink: string;
}

export const utils = {
  getAppServiceUrl: (): string => {
    return window.location.origin;
  },
  getTokenForUser: async (): Promise<CommunicationUserToken> => {
    const common = store.getState().common;
    const config = {
      headers: {
        Authorization: `Bearer ${common.token}`
      }
    };
    const response = await fetch(appconfig.azure_url + '/token', config);
    if (response.ok) {
      return response.json();
    }
    throw new Error('Invalid token response');
  },
  getMeetinglink: async (): Promise<MeetingLink> => {
    const common = store.getState().common;
    const config = {
      headers: { Authorization: `Bearer ${common.token}` }
    };
    const response = await fetch(appconfig.azure_url + '/api/teams-meeting', config);
    if (response.ok) {
      return response.json();
    }
    throw new Error('Invalid url response');
  },
  getRefreshedTokenForUser: async (identity: string): Promise<string> => {
    const common = store.getState().common;
    const config = {
      headers: {
        Authorization: `Bearer ${common.token}`
      }
    };
    const response = await fetch(appconfig.azure_url + '/refreshToken/${identity}', config);
    if (response.ok) {
      const content = await response.json();
      return content.token;
    }
    throw new Error('Invalid token response');
  },
  isSelectedAudioDeviceInList(selected: AudioDeviceInfo, list: AudioDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  },
  isSelectedVideoDeviceInList(selected: VideoDeviceInfo, list: VideoDeviceInfo[]): boolean {
    return list.filter((item) => item.name === selected.name).length > 0;
  },
  isMobileSession(): boolean {
    return window.navigator.userAgent.match(/(iPad|iPhone|iPod|Android|webOS|BlackBerry|Windows Phone)/g)
      ? true
      : false;
  },
  isSmallScreen(): boolean {
    return window.innerWidth < 700 || window.innerHeight < 400;
  },
  isUnsupportedBrowser(): boolean {
    //return window.navigator.userAgent.match(/(Firefox)/g) ? true : false;
    return window.navigator.userAgent.match(/(Firefox)/g) ? true : false;
  },
  getId: (identifier: CommunicationIdentifierKind): string => {
    switch (identifier.kind) {
      case 'communicationUser':
        return identifier.communicationUserId;
      case 'phoneNumber':
        return identifier.phoneNumber;
      case 'microsoftTeamsUser':
        return identifier.microsoftTeamsUserId;
      case 'unknown':
        return identifier.id;
    }
    return 'NULL';
  },
  getStreamId: (userId: string, stream: RemoteVideoStream): string => {
    return `${userId}-${stream.id}-${stream.mediaStreamType}`;
  },
  /*
   * TODO:
   *  Remove this method once the SDK improves error handling for unsupported browser.
   */
  isOnIphoneAndNotSafari(): boolean {
    const userAgent = navigator.userAgent;
    // Chrome uses 'CriOS' in user agent string and Firefox uses 'FxiOS' in user agent string.
    if (userAgent.includes('iPhone') && (userAgent.includes('FxiOS') || userAgent.includes('CriOS'))) {
      return true;
    }
    return false;
  },
  getBuildTime: (): string => {
    const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`;
    return dateTimeStamp;
  },

  registerEvent: (eventType: string) => {
    const body = { eventName: eventType };
    const queryParams = new URLSearchParams(window.location.search);
    const meetingID = queryParams.get('meetingID');
    if (meetingID == null || meetingID == '') {
      return;
    }
    const url = appconfig.azure_url + '/api/customer-meeting/events/' + meetingID;
    //alert(url);
    console.log('register event url = ' + url);
    const common = store.getState().common;
    const config = {
      headers: {
        Authorization: `Bearer ${common.token}`,
        apiToken: common.token
      }
    };
    axios.post(url, body, config).then((response) => {
      console.log('register event response status= ' + response.status);
    });
  },
  startRecording: (serverCallId: string) => {
    const url = appconfig.azure_url + '/record/startRecording?serverCallId=' + serverCallId;
    console.log('startRecording  url = ' + url);
    const common = store.getState().common;
    const config = {
      headers: {
        Authorization: `Bearer ${common.token}`,
        apiToken: common.token
      }
    };
    axios.get(url, config).then((response) => {
      console.log('startRecording  response status= ' + response.status);
    });
  },
  // Function to check if a string contains Arabic text
  isArabic: (text: string): boolean => {
    // Regular expression to match Arabic characters
    const arabicRegex = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDFF\uFE70-\uFEFF]/;
    return arabicRegex.test(text);
  },
  getFirstWord: (text: string): string => {
      // Split the text by spaces and filter out any empty strings
      const words = text
        .trim()
        .split(/\s+/)
        .filter((word) => word.length > 0);
      // Return the first word if it exists, otherwise return an empty string
      return words.length > 0 ? words[0] : '';
  }
};
