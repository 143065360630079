import { useCallback } from 'react';
 
export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): T {
    let timer: NodeJS.Timeout;
    return function(this: any, ...args: any[]) {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    } as T;
}

export function useDebounce<T extends (...args: any[]) => void>(callback: T, delay: number): T {
    return useCallback(debounce<T>(callback, delay), [callback, delay]);
}