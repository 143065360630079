import React , { Component } from "react"
import 'bootstrap/dist/css/bootstrap.css';
class WaitinfSessionExpired extends Component{
 
  /*openHome = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let isPublic = urlParams.get("isPublic");
    window.location.href = "/?isPublic="+isPublic;  
  }*/
    render(){
      return(
        <div id="main">
          <div className="main-container">

            <div className="row">
              <div className="col-md-8">
                 
              </div>
            </div>

            <div className="row">
            <div className="col-md-2" ></div>
              <div className="col-md-9" >
                  <p>
                  عميلنا العزيز،
                  <br></br>
نقدر وقتك، و نفيدك بأن جميع الموظفين مشغولون في خدمة عملاء آخرين. نأمل منك المحاولة في وقت لاحق
وتسعدنا خدمتك عبر قنوات التواصل الاخرى
و يمكنك الاستفادة من خدماتنا الإلكترونية وذلك بزيارة
&nbsp; 
<a href="https://www.gosi.gov.sa/ar/ContactUs" >صفحة الخدمات</a>
 

                    </p>
                    <br>
                    </br>
                   

              </div>
            
            </div>
           
          </div>
        </div>
      );
    }
}
export default WaitinfSessionExpired;
