import React, { Component } from "react";
import { setFullName, setMobile, setNin, setServiceType } from "../core/actions/common";
import { connect } from "react-redux";
import { State } from '../core/reducers';
export interface CommonProps {
    serviceType: string;
    fullName: string;
    nin: string;
    mobile: string;
    token: string;
    setServiceType(serviceType: string): void;
    setFullName(fullName: string): void;
    setNin(nin: string): void;
    setMobile(mobile: string): void;
}
class MobileAPP extends Component {
    constructor(public props: CommonProps) {
      
        super(props);
        
    }
    componentDidMount(): void {
        
        const queryParams = new URLSearchParams(window.location.search);
        const fullName:any = queryParams.get("fullname");
        const nin:any = queryParams.get("nin");
        const mobile:any = queryParams.get("mobile");
        const serviceType:any = queryParams.get("serviceType");
        this.props.setFullName(Buffer.from(fullName,'base64').toString('utf-8'));
        this.props.setNin(Buffer.from(nin,'base64').toString('utf-8'));
        this.props.setMobile(Buffer.from(mobile,'base64').toString('utf-8'));
        this.props.setServiceType(Buffer.from(serviceType,'base64').toString('utf-8'));
        let url ="";
        if( serviceType === null || serviceType === "" ){
            alert("Please provide servie");
       }else if( nin === "" || nin === null ){
        alert("Please provide nin number");
       }else if( mobile ==="" || mobile === null ){
        alert("Please provide mobile number");
       }else if( fullName === "" || fullName === null ){
        alert("Please provide fullName");
       }else if(serviceType !== null && fullName !== null && nin !== null && mobile !== null && serviceType !== null){
            url = "/?isPublic=true&config=true";
            window.location.href = window.location.origin + url;
        }
        //const url = "/?isPublic=true&config=true&fullname=dasarathi&nin=1011012345&mobile=9087654321&serviceType=gosi-ppa&client=null";
         
    }
    render() {
        return (
            <div>
                
            </div>
        );
    }
}
const mapStateToProps = (state: State, props: CommonProps) => ({
    serviceType: state.common.serviceType
});

const mapDispatchToProps = (dispatch: any, props: CommonProps) => ({
    setServiceType: (serviceType: string) => dispatch(setServiceType(serviceType)),
    setFullName: (fullName: string) => dispatch(setFullName(fullName)),
    setNin: (nin: string) => dispatch(setNin(nin)),
    setMobile: (mobile: string) => dispatch(setMobile(mobile))
});

const connector: any = connect(mapStateToProps, mapDispatchToProps);
export default connector(MobileAPP);
//export default MobileAPP;
